<template>
  <div>
    <a role="button" class="launch-tile" :class="{ 'is-recent': isRecent }" :style="thumbnail" @click="open">
      <div class="inner">
        <div class="level is-mobile caption">
          <div class="level-left">
            <div class="level-item">
              {{ episode.date.toFormat('MMMM d, yyyy') }}
            </div>
          </div>
          <div class="level-right is-hidden-mobile">
            <div class="level-item">
              <small>{{ episode.length | secondsFormat }}</small>
            </div>
          </div>
        </div>
      </div>
    </a>
    <div class="modal" role="dialog" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-content" style="background-color: black;">
        <figure class="image" :class="ratio">
          <video controls autoplay class="has-ratio" poster="/images/video_thumb@2x.png" controlsList="nodownload" ref="video" v-if="isOpen">
            <source :src="video" type="video/mp4">
          </video>
        </figure>
      </div>
      <button class="modal-close is-large" @click="close"></button>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import utils from '@/lib/utils'

  export default {
    props: {
      episode: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        isOpen: false,
        modalContent: '',
      }
    },

    computed: {
      isRecent() {
        return DateTime.local() <= this.episode.date.plus({ days: 7 })
      },

      thumbnail() {
        return '' // background-image: url('');
      },

      video() {
        return utils.episode_path(this.episode.season, this.episode.date.toFormat('yyyy-MM-dd'), 'mp4')
      },

      ratio() {
        return (this.episode.hd || false) ? 'is-16by9' : 'is-4by3'
      },
    },

    beforeDestroy() {
      document.documentElement.classList.remove('is-clipped')
    },

    methods: {
      open() {
        this.isOpen = true
        document.documentElement.classList.add('is-clipped')
      },

      close() {
        this.isOpen = false
        document.documentElement.classList.remove('is-clipped')
      },
    },
  }
</script>

<style lang="scss">
  .launch-tile {
    display: block;
    position: relative;
    border-radius: $radius-large;
    box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 1px rgba($scheme-invert, 0.02);
    color: $text;
    background-image: radial-gradient($primary, darken($primary, 10%));
    transition: 0.1s 0s;

    // This forces the tile into a 4:3 ratio.
    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (3 / 4) * 100%;
    }

    &.is-recent::after {
      position: absolute;
      top: 8px;
      right: 8px;
      content: "NEW";
      background-color: rgba(255, 255, 0, 0.9);
      border-radius: $radius-large;
      line-height: 1;
      padding: 4px 6px;
    }

    @include desktop {
      opacity: 0.95;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 2px 12px rgba(10, 10, 10, 0.3);
        opacity: 1;
      }

      &:active {
        transform: scale(1.025);
        opacity: 1;
      }
    }

    @include mobile {
      &:active {
        transform: scale(1.05);
        box-shadow: 0 2px 12px rgba(10, 10, 10, 0.3);
        opacity: 1;
      }
    }

    > .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &::before {
        content: "";
        background-image: url('../assets/timberwolf.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 35%;
        opacity: 0.75;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

      .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 6px 10px;
        background: linear-gradient(rgba(10, 10, 10, 0.25), rgba(10, 10, 10, 0.5));
        color: white;
        border-bottom-left-radius: $radius-large;
        border-bottom-right-radius: $radius-large;
        font-size: 1.1rem;

        small {
          font-size: 0.9rem;
        }
      }
    }
  }
</style>
