<template>
  <div class="container is-fluid">
    <div class="columns">
      <div class="column is-narrow is-hidden-mobile">
        <aside class="menu seasons-menu">
          <ul class="menu-list">
            <li v-for="season in seasons" :key="season.season">
              <router-link :to="{ name: 'episodes', params: { season: season.season } }">
                <strong>S{{season.season}}</strong> <small>{{ `${ 2000 + season.season }-${ 2000 + season.season + 1 }` }}</small>
              </router-link>
            </li>
          </ul>
        </aside>
      </div>
      <div class="column is-hidden-tablet">
        <div class="field">
          <div class="control">
            <div class="select is-fullwidth is-medium">
              <select v-model.number="selectedSeason">
                <option v-for="season in seasons" :key="season.season" :value="season.season">
                  {{ `Season ${season.season}: ${ 2000 + season.season }-${ 2000 + season.season + 1 }` }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="columns is-mobile is-multiline">
          <div class="column is-3-widescreen is-4-desktop is-6-tablet is-6-mobile" v-for="episode in episodes" :key="episode.uniqid">
            <episode-tile :episode="episode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import EpisodeTile from '@/components/EpisodeTile.vue'
  import { isFinite, orderBy } from 'lodash'

  export default {
    props: {
      season: Number,
    },

    data: () => ({
      seasons: [],
      selectedSeason: null,
    }),

    components: {
      EpisodeTile
    },

    computed: {

      episodes() {
        try {
          return this.seasons.find((s) => s.season === this.season).episodes
        } catch {
          return null
        }
      },

    },

    watch: {

      season(to, from) {
        this.init(to)
      },

      selectedSeason(to, from) {
        this.gotoSeason(to)
      },

    },

    methods: {

      init(season) {
        if (!isFinite(season)) {
          this.gotoSeason(this.seasons[0].season)
        } else {
          this.selectedSeason = season
        }
      },

      gotoSeason(season) {
        if (season !== this.season) {
          this.$router.push({ name: 'episodes', params: { season: season } })
        }
      },

    },

    async created() {

      // Load the episode catalog.
      const catalog = await this.$cdn.get(`data/episodes.v2.json?${new Date().getTime()}`)

      // Group the catalog by season.
      this.seasons = orderBy([ ...catalog.data.reduce((seasons, episode) => {

        // Convert the date to an object.
        episode.date = DateTime.fromISO(episode.date)

        // Calculate the season.
        episode.season = episode.date.year - (episode.date.month < 8 ? 2001 : 2000)

        // Generate a unique ID; used for the v-for key.
        episode.uniqid = `S${episode.season}E${episode.date.toFormat('yyooo')}`

        // Append to (or create) the season.
        const prev = seasons.get(episode.season)
        if (prev) {
          prev.episodes.push(episode)
        } else {
          seasons.set(episode.season, { season: episode.season, episodes: [ episode ] })
        }

        return seasons

      }, new Map()).values() ], 'season', 'desc').map((item) => {
        // Sort the episodes by date, descending.
        item.episodes = orderBy(item.episodes, 'date', 'desc')
        return item
      })

      this.init(this.season)

    },
  }
</script>

<style lang="scss">
  @include mobile {
    .container.is-fluid {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .seasons-menu {
    li {
      font-size: 0.95em;
      text-align: right;
      word-wrap: none;
    }
  }
</style>
